import Vue from 'vue';
import getEnv from '@/plugins/env';
import axios from 'axios';

const reqInstance = axios.create({
  headers: {
    Authorization: `Token ${getEnv('VUE_APP_MAPS_SUGGEST_API_KEY')}`
  }
});

export default {
  async geocode({ commit }, payload) {
    return await Vue.axios.get(
      `https://geocode-maps.yandex.ru/1.x/?apikey=${getEnv('VUE_APP_MAPS_API_KEY')}&geocode=${payload}&format=json`
    ).then(({ data }) => data?.response?.GeoObjectCollection?.featureMember[0]?.GeoObject || {});
  },
  async getHints({ commit }, payload) {
    const data = {
      query: payload,
      count: 10
    };
    return await reqInstance.post(
      'https://data.pbprog.ru/api/address/full-address/parse',
      data
    ).then(({ data }) => data?.suggestions?.map(el => ({
      id: el?.data?.fias_id,
      postal_code: el?.data?.postal_code,
      value: el?.value
    })) || null )
  }
};
