<template>
  <div
    class="d-flex flex-direction-column justify-content-center align-items-center"
    style="position: relative; height: 100%; width: 100%;"
  >
    <section
      class="d-flex flex-direction-column pa-8"
      style="width: 678px;"
    >
      <div
        slot="title"
        class="d-flex align-items-center justify-content-between mb-8"
      >
        <p class="camembert">
          Фильтры
        </p>
        <r-button-action
          icon="clean"
          title="Очистить"
          color="lebowski"
          @click="removeFilters"
        />
      </div>

      <section class="mb-8">
        <p class="feta troy--text mb-4">По срокам</p>
        <div class="d-flex align-items-center">
          <FormSelect
            class="flex-1"
            label="Сортировка"
            idValue="code"
            titleValue="name"
            :items="orderList"
            v-model="filter.sortBy"
          />
          <FormSelect
            v-if="isInProgress"   
            class="flex-1 ml-6"
            label="Статус исполнения"
            :items="disciplineList"
            v-model="filter.discipline"
            idValue="code"
            titleValue="name"
            isSelectAll
          />
        </div>
        <div
          v-if="isInProgress"
          class="d-flex align-items-center mt-6"
        > 
          <FormDatePicker
            v-if="isRemoved"
            class="flex-1"
            label="Дата удаления"
            v-model="$v.filter.endPeriod.$model"
            :error="$v.filter.endPeriod.$error"
            :errorMessage="mesErr($v.filter.endPeriod)"
            period
          />
          <FormDatePicker
            v-else
            class="flex-1"
            label="Срок исполнения внешний"
            v-model="$v.filter.extPeriod.$model"
            :error="$v.filter.extPeriod.$error"
            :errorMessage="mesErr($v.filter.extPeriod)"
            period
            :disabled="!extDueDateEnabled"
          />
          <FormDatePicker
            class="flex-1 ml-6"
            label="Срок исполнения внутренний"
            v-model="$v.filter.innerPeriod.$model"
            :error="$v.filter.innerPeriod.$error"
            :errorMessage="mesErr($v.filter.innerPeriod)"
            period
          />
        </div>
        <div class="d-flex align-items-center mt-6">
          <FormDatePicker
            v-if="isSolved"
            class="flex-1"
            label="Дата завершения"
            v-model="$v.filter.endPeriod.$model"
            :error="$v.filter.endPeriod.$error"
            :errorMessage="mesErr($v.filter.endPeriod)"
            period
          />
          <div class="flex-1 ml-6"></div>
        </div>
      </section>

      <section class="mb-8">
        <p class="feta troy--text mb-4">По тегам</p>
        <div class="d-flex align-items-center">
          <MultiselectTags
            v-if="isShownKeywords"
            class="flex-1"
            v-model="filter.tags"
          />
          <r-checkbox
            class="flex-1 ml-6"
            title="Показывать непрочитанные"
            v-model="filter.unread"
          />
        </div>
        <r-checkbox
          v-if="isInProgress && isMedialogy"
          class="mt-6"
          title="Показывать обращения с новыми комментариями "
          v-model="filter.newComment"
          not-shorter
        />
      </section>

      <section class="mb-8" v-if="isInProgress">
        <p class="feta troy--text mb-4">По исполнителям</p>
        <div class="d-flex align-items-center">
          <FormSelect
            class="flex-1"
            label="Департамент"
            :items="departments"
            :value="filter.departmentId"
            @input="changeDepartment"
            idValue="id"
            titleValue="name"
            isSelectAll
          />
          <FormSelect
            class="flex-1 ml-6"
            label="Ответственный"
            :items="executorsInDepartment"
            v-model="filter.executorId"
            :disabled="!executorsInDepartment.length"
            idValue="id"
            titleValue="fullName"
            isSelectAll
          />
        </div>
      </section>

      <r-button
        class="mt-8"
        @click="searchAndClose"
        title="Применить"
        width="wide"
        :disabled="isDisabledSubmitButton"
      />
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import MultiselectTags from '@/components/MultiselectTags';
import FormSelect from '@/components/FormSelect'
import FormTextarea from '@/components/FormTextarea'
import FormDatePicker from '@/components/FormDatePicker'
import { validationMixin } from 'vuelidate'
import validateMixin from '@/plugins/vuelidate/validateMixin'
import { period } from '@/plugins/vuelidate/customValidate'
import moment from 'moment';
import getEnv from '@/plugins/env'
import { STATES } from '@/constants'

const defaultFilters = {
  departmentId: [],
  executorId: [],
  innerPeriod: null,
  extPeriod: null,
  endPeriod: null,
  sortBy: null,
  discipline: [],
  unread: false,
  newComment: false,
  tags: []
};

const oneDay = 86400000;

export default {
  name: 'FiltersModal',
  components: {
    MultiselectTags,
    FormSelect,
    FormTextarea,
    FormDatePicker
  },
  mixins: [validationMixin, validateMixin],
  data () {
    return {
      appealTypes: [],
      disciplineList: [
        { name: 'Не просрочено', code: 'ON_TIME' },
        { name: 'Просрочено', code: 'OVERDUE' }
      ],
      filter: { ...defaultFilters },
      innerPeriodIsChanged: false,
      extPeriodIsChanged: false,
      endPeriodIsChanged: false,
      isDisabledSubmitButton: true
    };
  },
  validations () {
    return {
      filter: {
        innerPeriod: {
          period: period('Введите период')
        },
        extPeriod: {
          period: period('Введите период')
        },
        endPeriod: {
          period: period('Введите период')
        },
      }
    }
  },
  async created() {
    await this.loadDirectories();
    this.appealTypes = await this.getIncidentTypes();
    this.filter = {
      ...defaultFilters,
      sortBy: this.orderList[0].code,
      ...this.globalFilters,
      innerPeriod: this.globalFilters.dueDateFrom && this.globalFilters.dueDateTo
      ? [moment.unix(this.globalFilters.dueDateFrom / 1000).format('YYYY-MM-DD'),
      moment.unix((this.globalFilters.dueDateTo - oneDay) / 1000).format('YYYY-MM-DD')]
      : null,
      extPeriod: this.globalFilters.extDueDateFrom && this.globalFilters.extDueDateTo
      ? [moment.unix(this.globalFilters.extDueDateFrom / 1000).format('YYYY-MM-DD'),
      moment.unix((this.globalFilters.extDueDateTo - oneDay) / 1000).format('YYYY-MM-DD')]
      : null,
      endPeriod: this.globalFilters.endDateFrom && this.globalFilters.endDateTo
      ? [moment.unix(this.globalFilters.endDateFrom / 1000).format('YYYY-MM-DD'),
      moment.unix((this.globalFilters.endDateTo - oneDay) / 1000).format('YYYY-MM-DD')]
      : null,
      departmentId: this.globalFilters.departmentId || [],
      executorId: this.globalFilters.executorId || [],
      discipline: this.globalFilters.discipline || [],
      tags: this.globalFilters.tags || [],
    };
    if (this.globalFilters.dueDateFrom) this.innerPeriodIsChanged = true;
    if (this.globalFilters.extDueDateFrom) this.extPeriodIsChanged = true;
    if (this.globalFilters.endDateFrom) this.endPeriodIsChanged = true;
    delete this.filter.dueDateFrom;
    delete this.filter.dueDateTo;
    delete this.filter.extDueDateFrom;
    delete this.filter.extDueDateTo;
    console.log(this.filter)
  },
  computed: {
    ...mapState('directory', ['messageSources', 'departments', 'executors', 'statuses', 'systemCode']),
    ...mapGetters('incident', ['filtersCount']),
    ...mapState('incident', { globalFilters: 'filters' }),
    executorsInDepartment() {
      return this.executors?.filter(el => this.filter.departmentId?.includes(el.departmentId))
    },
    isMedialogy() {
      return this.globalFilters.systemCode === 'MEDIALOGIA-IM';
    },
    isInProgress() {
      return [STATES.IN_WORK,
        STATES.WAITING_DECISION,
        STATES.SOLVED,
        STATES.REMOVED
      ].includes(this.$route.meta.type)
    },
    isSolved () {
      return this.$route.meta.type === STATES.SOLVED
    },
    isRemoved () {
      return this.$route.meta.type === STATES.REMOVED
    },
    isShownKeywords() {
      return !!+getEnv('VUE_APP_SHOW_TAGS');
    },
    extDueDateEnabled () {
      return this.systemCode.extDueDateEnabled
    },
    orderList () {
      return [
        { name: 'Дата обновления', code: 'UPDATED_AT' },
        { name: 'Дата поступления', code: 'CREATED_AT' },
        ... !['incident-deleted', 'incident-completed'].includes(this.$route.name) ? [{ name: 'Срок из внешней системы', code: 'EXT_DUE_DATE' }] : [],
        ... ['incident-work', 'incident-additional-question', 'incident-paused'].includes(this.$route.name) ? [{ name: 'Срок исполнения', code: 'EXECUTION_DATE' }] : [],
        ... ['incident-completed'].includes(this.$route.name) ? [{ name: 'Дата решения обращения', code: 'END_TS' }] : []
      ]
    },
  },
  watch: {
    'filter.innerPeriod': function (newVal) {
      if (!newVal.length) return;
      this.innerPeriodIsChanged = true;
    },
    'filter.extPeriod': function (newVal) {
      if (!newVal.length) return;
      this.extPeriodIsChanged = true;
    },
    'filter.endPeriod': function (newVal) {
      if (!newVal.length) return;
      this.endPeriodIsChanged = true;
    },
    filter: {
      handler (val, oldVal) {
        const filter = JSON.stringify(val)
        const filterOld = JSON.stringify(oldVal)
        this.isDisabledSubmitButton = !!(filter !== filterOld)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('directory', ['loadDirectories']),
    ...mapActions('incident', ['getIncidentTypes', 'getIncidents', 'getIncidentCount']),
    ...mapMutations('incident', ['setFilterProperty', 'clearFilterProperties', 'setProperty']),
    changeDepartment (val) {
      this.filter.departmentId = val
      this.filter.executorId = []
    },
    searchAndClose() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      if (this.filter.innerPeriod && typeof (this.filter.innerPeriod[0]) === 'number') {
        this.filter.innerPeriod = this.filter.innerPeriod.map(item => moment.unix(item / 1000).format('YYYY-MM-DD'));
      }
      if (this.filter.extPeriod && typeof (this.filter.extPeriod[0]) === 'number') {
        this.filter.extPeriod = this.filter.extPeriod.map(item => moment.unix(item / 1000).format('YYYY-MM-DD'));
      }
      if (this.filter.endPeriod && typeof (this.filter.endPeriod[0]) === 'number') {
        this.filter.endPeriod = this.filter.endPeriod.map(item => moment.unix(item / 1000).format('YYYY-MM-DD'));
      }
      const filters = { ...this.filter };
      if (this.innerPeriodIsChanged) {
        filters.dueDateFrom = Date.parse(filters.innerPeriod[0]) - 10800000;
        filters.dueDateTo = Date.parse(filters.innerPeriod[1]) - 10800000 + oneDay;
      }
      if (this.extPeriodIsChanged) {
        filters.extDueDateFrom = Date.parse(filters.extPeriod[0]) - 10800000;
        filters.extDueDateTo = Date.parse(filters.extPeriod[1]) - 10800000 + oneDay;
      }
      if (this.endPeriodIsChanged) {
        filters.endDateFrom = Date.parse(filters.endPeriod[0]) - 10800000;
        filters.endDateTo = Date.parse(filters.endPeriod[1]) - 10800000 + oneDay;
      }
      delete filters.innerPeriod;
      delete filters.extPeriod;
      delete filters.endPeriod;
      for (const [attr, value] of Object.entries(filters)) {
        const isArr = Array.isArray(value)
        this.setFilterProperty({
          attr,
          value: !isArr || (isArr && value.length !== 0) ? value : undefined
        });
      }
      this.resetIncidents();
      this.filtersCount
        ? this.getIncidentCount({ category: [this.$route.meta.type] })
        : this.setProperty({ name: 'incidentCount', value: 0 })
      this.$root.$modal.closeModal();
    },

    resetIncidents() {
      this.setProperty({ name: 'incidents', value: [] });
      this.setProperty({ name: 'appealActions', value: [] });
      this.setFilterProperty({ attr: 'pageIndex', value: 0 });
      this.getIncidents({
        category: [this.$route.meta.type],
        sortBy: this.filter.sortBy
      });
    },

    removeFilters() {
      const { category, systemCodes, accountIds, accountChannelCode } = this.globalFilters;
      this.setProperty({ name: 'filters', value: {
        pageIndex: 0,
        size: 20,
        category,
        systemCodes,
        accountIds,
        accountChannelCode
      } });
      accountIds?.length || accountChannelCode?.length
        ? this.getIncidentCount({ category: [this.$route.meta.type] })
        : this.setProperty({ name: 'incidentCount', value: 0 });
      this.filter = defaultFilters;
      this.filter.sortBy = this.orderList[0].code;
      this.innerPeriodIsChanged = false;
      this.extPeriodIsChanged = false;
      this.endPeriodIsChanged = false;
      this.resetIncidents();
      this.$root.$modal.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  .flex-1 {
    flex: 1
  }
</style>
