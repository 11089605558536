<template>
  <div
    id="app"
    class="app"
  >
    <r-notification />
    <r-modal ref="modal"/>
    <component :is="$route.meta.layout" />
    <transition name="slide-fade">
      <div
        v-if="showUpdateListNotification"
        class="update-notification "
        :class="{'update-notification--open': isSidebarOpen}"
      >
        <div class="update-notification__item">
          <span class="bryndza">Поступили новые обращения.</span>
          <a
            class="bryndza ml-2"
            @click="updateIncidentList"
          >Обновить список?</a>
          <r-icon
            fill="rocky"
            @click.native="closeNotification"
            class="update-notification__close ekas"
            icon="close"
            size="16"
          />
        </div>
      </div>
    </transition>
    <!-- <right-side-notification /> -->
  </div>
</template>
<script>
import getEnv from '@/plugins/env';
import { mapActions, mapMutations, mapState } from 'vuex';
import Vue from 'vue';
import RightSideNotification from '@/components/RightSideNotification';
import InfoModal from '@/views/incident/modal/InfoModal';

export default {
  name: 'App',
  components: { Notification, RightSideNotification },
  data() {
    return {
      showUpdateListNotification: false,
      isFiltered: false
    };
  },
  watch: {
    filtersStringify() {
      this.checkIsFiltered();
    }
  },
  computed: {
    ...mapState('incident', ['filters']),
    ...mapState('apps', ['isSidebarOpen']),
    ...mapState('session', ['areaCode', 'user']),
    filtersStringify() {
      return JSON.stringify(this.filters);
    }
  },
  async mounted() {
    Vue.prototype.$uploadServer = `${getEnv('VUE_APP_API_SERVER')}upload`;
    this.$root.$modal = this.$refs.modal;
    this.startWS(getEnv('VUE_APP_SOCKET_SERVER'));
    const res = await this.geocode([getEnv('VUE_APP_DEFAULT_LONGITUDE'), getEnv('VUE_APP_DEFAULT_LATITUDE')])
    const prefix = res?.metaDataProperty?.GeocoderMetaData?.Address?.Components?.find(el => el.kind === 'locality').name
    this.setMapProperty({ name: 'addressPrefix', value: prefix || '' })
  },
  methods: {
    ...mapMutations('incident', ['addIncident', 'removeIncident', 'changeMark', 'setFilterProperty', 'pushActions', 'setActions', 'pushActions']),
    ...mapMutations('directory', ['setProperty']),
    ...mapMutations('map', { setMapProperty: 'setProperty' }),
    ...mapMutations('notification', ['pushNotifications']),
    ...mapActions('incident', ['getIncidents']),
    ...mapActions('map', ['geocode']),
    startWS(WSServerLocation) {
      const socket = new WebSocket(WSServerLocation);
      socket.onopen = () => {
        socket.send(JSON.stringify({ token: this.$keycloak.token }));
      };
      socket.onmessage = event => {
        try {
          const item = JSON.parse(event.data);
          if (item.type === 'appeal' && this.isFiltered && !this.$route.params.id && !!this.$route.name.match(/incident/)) {
            this.showUpdateListNotification = true;
          } else {
            this.showUpdateListNotification = false;
            switch (item.type) {
              case 'notification':
                this.pushNotifications(item.data);
                break;
              case 'appeal':
                this.checkAppealStatus(item.data);
                if (item.data.category === this.$route.meta.type) {
                  if (this.filters.systemCodes) {
                    if (this.filters.systemCodes === item.data.systemCodes) this.addIncident(item.data);
                  } else {
                    this.addIncident(item.data);
                  }
                } else {
                  this.removeIncident(item.data);
                }
                break;
              case 'count_by_system':
                this.setProperty({
                  name: 'appealSystemCounter',
                  value: item.data
                });
                break;
              case 'actions':
                this.pushActions(item.data);
                this.setActions();
                break;
              case 'read':
                this.changeMark({
                  modelId: item.data.id,
                  status: item.data.isRead
                });
                break;
            }
          }
        } catch {}
      };
      socket.onclose = () => {
        setTimeout(() => {
          this.startWS(WSServerLocation);
        }, 5000);
      };
    },
    async updateIncidentList() {
      this.setFilterProperty({ attr: 'pageIndex', value: 0 });
      await this.getIncidents({
        category: [this.$route.meta.type],
        sortBy: this.$route.meta.sortBy
      });
      this.showUpdateListNotification = false;
    },
    checkIsFiltered() {
      const filters = [];
      for (const key in this.filters) {
        if (key !== 'pageIndex' && key !== 'systemCodes' && key !== 'size' && this.filters[key]) {
          filters.push(this.filters[key]);
        }
      }
      this.isFiltered = !!filters?.length;
    },
    closeNotification() {
      this.showUpdateListNotification = false;
    },
    checkAppealStatus(appeal) {
      if (appeal.id === this.$route.params.id && appeal.lastModeratorId !== this.user.id && appeal.currentAreaCode !== this.areaCode && appeal.areaCode !== this.areaCode) {
        if (this.$root.$modal.isOpen) return
        this.$root.$modal.openModal(InfoModal, {
          icon: 'warning',
          fill: 'lebowski',
          title: 'Обращение неактуально',
          subtitle: `Координатор отменил назначенное на вас обращение или удалил его.
            Вы можете взять в работу другое обращение.`,
          button: 'Вернуться к списку новых обращений'
        });
      }
    }
  }
};
</script>

<style lang="scss">
body.widest .RLayoutDashboard__menu.compact+.RLayoutDashboard__body .update-notification {
  width: calc(100% - 64px);
}

.link {
  display: inline-block;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  padding: 1px 0px;
  overflow: hidden;
  vertical-align: bottom;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transform: translateY(calc(100% - 2px));
    width: 100%;
    height: 100%;
    background-color: var(--rir-alien);
    transition: transform .2s ease;
  }

  &:hover::before {
    transform: translateY(0);
    transition: transform .2s ease;
  }
}

a {
  text-decoration: none !important;
  color: var(--rir-harakiri) !important;
}

// ВОТ ЭТО ВСЕ СНЕСТИ НАХРЕН ПОСЛЕ ПЕРЕВОДА НА ДС
body.widest {
  [class^="rir-"], [class*=" rir-"] {
    display: block;
  }

  .RAccordion__content {
    overflow: visible;
  }

  .RModal__content {
    overflow: visible !important;
    overflow-x: visible !important;
  }

  .RPopover__content {
    overflow: visible !important;
  }

  .rir-input {
    display: flex;
    background-color: var(--rir-django) !important;

    &__input > input {
      color: var(--rir-metropolis) !important;
    }
  }

  .RBulb__badge.fargo.shape {
    background-color: var(--rir-fargo) !important;
  }
  .RBulb__badge.rocky.shape {
    background-color: var(--rir-rocky) !important;
  }

  .RButton.content {
    display: flex;
    align-items: center;
  }

  .rir-input__label {
    font-family: Golos,sans-serif !important;
  }

  .rir-list-item {
    font-family: Golos,sans-serif !important;
  }

  .rir-date-picker {
    display: inline-flex !important;
  }

  .rir-date-picker span, .rir-date-picker a {
    font-family: Golos,sans-serif !important;
  }

  .rir-date-picker__week, .rir-date-picker__row {
    display: grid;
  }

  .rir-date-picker__navigation, .rir-date-picker__time, .rir-date-picker__column {
    display: flex;
  }


  .rir-select__icon, .rir-input__after, .rir-input__before {
    display: flex;
  }

  .rir-select__icon .rir-icon > svg {
    width: 16px;
    height: 16px;
    & path {
      fill: var(--rir-metropolis);
    }
  }

  .RIcon {
    display: inline-flex !important;
  }
}

@mixin clamp ($num) {
  -webkit-line-clamp: $num;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}

.clamp-1 { @include clamp(1) }
.clamp-2 { @include clamp(2) }
.clamp-3 { @include clamp(3) }


.update-notification {
  position: fixed;
  right: 0;
  bottom: 16px;
  padding: 0 16px;
  width: calc(100% - 25%);
  z-index: 9999;
  transition: .3s ease;

  &--open {
    width: calc(100% - 344px);
  }

  &__item {
    background: var(--rir-schindler);
    box-shadow: 0px 8px 32px rgba(9, 33, 82, 0.12);
    border-radius: 28px;
    height: 56px;
    padding: 0 24px;
    display: flex;
    align-items: center;
  }

  &__close {
    margin-left: auto;
    padding-left: 16px;
    cursor: pointer;
  }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: .3s ease;
}
.slide-fade-enter {
  transform: translateY(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.app {
  &::v-deep {

    .r-modal__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 32px 32px;
    }

    .r-file-list {
      &__img {
        height: 100%;
      }

      &__item {
        min-width: 0;
      }
    }

    .r-app-layout__body {
      left: 344px;
      width: calc(100% - 344px);
    }

    .r-app-layout__sidebar {
      width: 344px;
    }

    .r-app-layout__body::before {
      left: 344px;
    }

    .r-input__label {
      color: rgba(3,21,58,0.5)!important;
    }
  }
}
</style>
