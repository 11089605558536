<template>
  <div class="consolidate-modal" id="modal">
    <div class="consolidate-modal__wrapper">
      <div class="consolidate-modal__header">
        <div class="consolidate-modal__title">
          <h2 class="camembert mb-4 titanic--text">Объединение обращений</h2>
          <p class="mb-4 parmigiano">
            Выберите обращение, которые хотите добавить к обращению №{{ incident.number }},
            {{ selectedIncidents ? `(выбрано ${selectedIncidents.length})` : '' }}
          </p>
          <r-input
            v-model="searching"
            @input="debounceInput"
            label="Поиск"
          />
        </div>
      </div>

      <div v-if="filteredIncidents.length">
        <IncidentItem
          v-for="incident in filteredIncidents"
          :key="incident.id"
          :incident="incident"
          :isShownButtons="false"
        />
      </div>
      <div v-if="isLoading" class="consolidate-modal__loading-ring">
        <r-spinner/>
      </div>
      <empty
        v-else-if="!filteredIncidents.length"
        class="mt-8"
        title="К сожалению, ничего не найдено"
      />

      <div id="RModal--actions" class="consolidate-modal__footer">
        <div class="consolidate-modal__buttons">
          <r-button
            class="consolidate-modal__button"
            @click="closeModal()"
            color="secondary"
            title="Отменить"
          />
          <r-button
            class="consolidate-modal__button ml-8"
            @click="openConsolidateSubmitModal"
            :disabled="isButtonDisabled"
            title="Объединить"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { debounce } from '@/plugins/lib'
import ConsolidateSubmitModal from '@/views/incident/modal/ConsolidateSubmitModal'
import Empty from '@/components/Empty'
import { STATES } from '@/constants'

export default {
  name: 'ConsolidateIncidentsModal',
  components: {
    Empty,
    IncidentItem: () => import('../components/IncidentItem.vue')
  },
  props: {
    incident: {
      type: Object
    }
  },
  data () {
    return {
      searching: null,
      modal: null
    }
  },
  computed: {
    ...mapState('incident', ['filters', 'isLoading', 'incidents', 'isSelectionMode', 'selectedIncidents', 'appealActions']),
    filteredIncidents () {
      return this.incidents.filter((incident) => incident.id !== this.incident.id)
    },
    isButtonDisabled () {
      return !this.selectedIncidents.length
    },
    isMedialogy() {
      return ['MEDIALOGIA-IM'].includes(this.incident.systemCode);
    },
    inProgress() {
      return this.$route.meta.type !== STATES.REMOVED
        && this.$route.meta.type !== STATES.SOLVED
        && this.$route.meta.type !== STATES.ERROR;
    },
  },
  async mounted () {
    this.setSelectionMode(true)
    this.resetIncidents()
    await this.fetchIncidentList()
    this.modal = document.getElementById('modal')
    this.modal.addEventListener('scroll', e => this.showNewIncidents(e.target.scrollTop))
  },
  beforeDestroy () {
    this.modal.removeEventListener('scroll', e => this.showNewIncidents(e.target.scrollTop))
    this.setSelectionMode(false)
  },
  methods: {
    ...mapMutations('incident', ['setProperty', 'setFilterProperty', 'setSelectionMode', 'clearFilterProperties', 'setExecutionType']),
    ...mapActions('incident', ['getIncidents', 'consolidateIncidents', 'getIncidentActions']),
    resetIncidents () {
      this.clearFilterProperties()
      this.setProperty({ name: 'incidents', value: [] })
      this.setProperty({ name: 'appealActions', value: [] })
      this.setFilterProperty({ attr: 'pageIndex', value: 0 })
    },
    fetchIncidentList () {
      this.getIncidents({
        delegate: false,
        consolidate: false,
        category: [STATES.NEW, STATES.ADDITIONAL_QUESTION]
      })
    },
    showNewIncidents (scroll) {
      if ((this.modal.offsetHeight + scroll) >= this.modal.scrollHeight - 50) {
        if (this.filteredIncidents.length > this.filters.pageIndex * 20) {
          this.setFilterProperty({ attr: 'pageIndex', value: this.filters.pageIndex + 1 })
          this.fetchIncidentList()
        }
      }
    },
    openConsolidateSubmitModal () {
      this.$root.$modal.openModal(ConsolidateSubmitModal, {
        incident: this.incident,
        selectedIncidents: this.selectedIncidents
      })
    },
    async getActions(id) {
      if (this.$route.meta.type !== STATES.SOLVED) {
        await this.getIncidentActions({ id });
        if (this.isMedialogy && this.inProgress && this.appealActions?.publication) this.appealActions?.publication.push(ACTIONS.ADD_NOTE);
      }
    },
    debounceInput: debounce(function () {
      this.resetIncidents()
      this.setFilterProperty({ attr: 'searching', value: this.searching })
      this.fetchIncidentList()
    }, 1000),
    closeModal () {
      this.setExecutionType('')
      this.$rir.modal.close()
      if (this.$route.params.id) {
        this.getActions(this.incident.id)
      } else {
        this.resetIncidents()
        this.getIncidents({
          category: [this.$route.meta.type],
          sortBy: this.$route.meta.sortBy
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.consolidate-modal {
  height: calc(100% - 132px);
  position: relative;
  top: 150px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D4D5D932;
  }

  &__wrapper {
    width: 99%;
    margin-right: auto;
  }

  &__header {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 64px;
    padding-bottom: 24px;
    width: 100%;
  }

  &__title {
    max-width: 1360px;
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0px 4px 16px rgba(4, 21, 62, 0.16);
    padding: 24px 32px 32px 32px;
    width: 100%;
  }

  &__buttons {
    display: flex;
    max-width: 1360px;
    width: 100%;
  }

  &__button {
    flex: 1;
  }

  &__loading-ring {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
